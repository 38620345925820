<template>
    <section class="news">
        <Loading v-if="!news"></Loading>

        <template v-if="news">
            <Card
                class="news-card hide-news"
                @click="openModal(index)"
                v-for="(newsItem, index) in news"
                :key="newsItem.id"
            >
                <img :src="makeImageSrc(newsItem.image)" :alt="newsItem.title"/>
            </Card>
        </template>
        <div ref="observer" class="observer"></div>
    </section>

    <!-- MODAL -->
    <Modal title=" " :class="news_modal" v-if="showModal" @close="closeModal">
        <div class="news-modal-scroll">
            <swiper
                :initialSlide="activeSlide"
                :slidesPerView="1"
                :spaceBetween="30"
                :loop="true"
                :grabCursor="true"
                :autoHeight="true"
                :navigation="{
          prevEl: '.news-swiper-button .prevSlide',
          nextEl: '.news-swiper-button .nextSlide',
        }"
            >
                <swiper-slide v-for="newsItem in news" :key="newsItem.id">
                    <div class="news-image">
                        <img :src="makeImageSrc(newsItem.image)" :alt="newsItem.title"/>
                    </div>
                    <div class="news-content">
                        <div class="news-title">
                            <h4>{{ newsItem.title }}</h4>
                            <p>{{ newsItem.date }}</p>
                        </div>
                        <div v-html="newsItem.full_description"></div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <div class="news-swiper-button">
            <div class="prevSlide">
                <svg
                    width="24"
                    height="72"
                    viewBox="0 0 24 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M22.2939 1L1.70571 36L22.2939 71"
                        stroke="#E1E6EB"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                </svg>
            </div>
            <div class="nextSlide">
                <svg
                    width="24"
                    height="72"
                    viewBox="0 0 24 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.70605 1L22.2943 36L1.70605 71"
                        stroke="#E1E6EB"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                </svg>
            </div>
        </div>
    </Modal>
</template>

<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';
import SwiperCore, {Navigation} from 'swiper/core';

SwiperCore.use([Navigation]);

import Loading from '@/components/Loading.vue';
import Modal from '@/components/Modal.vue';

export default {
    name: 'News',
    components: {Modal, Loading, Swiper, SwiperSlide},
    data() {
        return {
            newsLimit: 12,
            showModal: false,
            activeSlide: 0,
        };
    },
    methods: {
        openModal(index) {
            this.activeSlide = index;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        makeImageSrc(path) {
            this.currentDomainZone = document.location.hostname.split('.')[document.location.hostname.split('.').length - 1];

            return process.env.NODE_ENV === "production"
                ? 'https://webvork.'+this.currentDomainZone+path
                : 'http://webvork.'+this.currentDomainZone+path;
        }
    },
    beforeMount() {
        this.$store.dispatch('webmaster/getNews');
    },
    computed: {
        news() {
            return this.$store.state.webmaster.news.reverse();
        },
    },
    updated() {
        let limit = 0;
        let news = document.querySelectorAll('.news .news-card');
        let options = {
            rootMargin: '0px',
            threshold: 1.0,
        };
        let callback = (entries, observer) => {
            if (entries[0].isIntersecting) {
                limit = this.newsLimit > news.length ? news.length : this.newsLimit;
                for (let newsItem = 0; newsItem < limit; newsItem++) {
                    news[newsItem].classList.remove('hide-news');
                }
                this.newsLimit += 12;
            }
        };
        let observer = new IntersectionObserver(callback, options);
        observer.observe(this.$refs.observer);
    },
};
</script>

<style lang="scss" scoped>
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.ui_modal_overlay {
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
}
.news-modal-scroll {
    max-height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    @extend .noScrollbar;

}
.ui_modal_title {
    padding: 0;
    border: none;
}
.hide-news {
    display: none;
}
.news-content {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #0d0d0d;
    p {
        padding-bottom: 15px;
    }
}
.news-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #a3b7ca;
    padding: 30px 0 15px;
    h4 {
        font-family: $font-primary;
        font-weight: bold;
        text-align: left;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.005em;
        color: #0d0d0d;
    }
}
.news-image {
    width: 100%;
    img {
        display: block;
        margin: 0 auto;
        min-width: 100%;
    }
}
.news-swiper-button {
    z-index: 1;
    width: calc(100% + 50%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .prevSlide,
    .nextSlide {
        cursor: pointer;
        width: 86px;
        height: 86px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }
    .prevSlide:hover,
    .nextSlide:hover {
        background: rgba(255, 255, 255, 0.335);
        backdrop-filter: blur(5px);
        path {
            stroke: black;
        }
    }
    @include maxWidth(846) {
        width: calc(100% + 180px);
    }
    @include maxWidth(660) {
        display: none;
    }
}
.observer {
    width: 100%;
    height: 1px;
    visibility: hidden;
}
</style>
